<div>
    <div class="offer-cart-form">
        <div v-if="isOpen" class="products-container">

            <div class="saved-products">
                <div class="product-group" v-for="productGroup in productGroups" :key="productGroup.name">
                    <p class="container-header">
                        {{ productGroup.name }}
                        <span v-if="productGroup.isEditing" class="editing">Текущий подбор</span>
                    </p>
                    <div class="product-info" v-for="product in productGroup.products" :key="product.id">
                        <p class="product-name">
                            {{ product.name }}
                            <span @click="deleteFromOffer(product.id)" class="delete">Удалить</span>
                        </p>
                        <counter :value="product.prices.count ?? 1" @on-change-value="product.prices.count = $event"/>
                    </div>
                </div>
            </div>
            <div class="to-add-products" v-if="productsToAdd.length > 0">
                <p class="container-header">Добавляем:</p>
                <div class="product-info" v-for="productId in productsToAdd" :key="'add_' + productId">
                    <p class="product-name to-add">
                        {{ getProductName(productId) }}
                        <span @click="removeFromToAdd(productId)" class="delete">Отменить</span>
                    </p>
                </div>
            </div>
            <div class="to-delete-products" v-if="productsToDelete.length > 0">
                <p class="container-header">Удаляем:</p>
                <div class="product-info" v-for="productId in productsToDelete" :key="'delete_' + productId">
                    <p class="to-delete">
                        {{ getProductName(productId) }}
                        <span @click="removeFromToDelete(productId)" class="delete">Отменить</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="controls-container">
            <button @click="updateOffer">Обновить оферту</button>
            <button v-if="currentOfferHash" @click="goToOfferEditor()">Вернуться в редактор (без сохранения)</button>
            <button @click="toggleOpen" class="minimize" :class="{open: isOpen}"><span class="icon arrow"></span></button>
        </div>
    </div>
    <Loader state="fixed" v-if="!isLoaded" :minHeight="199" />
</div>
