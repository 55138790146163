export default function (value, currency = 'RUB') {
    let n_val = value;

    if (typeof n_val === 'string') {

        n_val = parseFloat(n_val.replace(',', '.'));
    }

    return new Intl.NumberFormat(
        'ru-RU',
        {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        }
    ).format(n_val);
}
