<teleport to="body">
    <Modal
        v-if="showModal"
        @onClose="$emit('update:showModal', false)"
        :close-button="true"
        :buttons="[]"
    >
        <template v-slot:header></template>
        <template v-slot:body>
            <div class="d-flex px-5 w-75 justify-content-between">
                <div v-if="isPartnerSelected">
                    <div>Код контрагента: {{ orderInfo.partner_code }}</div>
                    <div>Контрагент: {{ orderInfo.partner_name }}</div>
                    <div>Контактное лицо: {{ contactInfo }}</div>
                </div>
                <div>
                    <a
                        @click.prevent="openPartnerModal"
                        class="cart-form__action"
                    >
                        <span class="icon users"></span>
                        <span>Выбрать контрагента</span>
                    </a>
                </div>
            </div>
            <form>
                <div class="modal-section">
                    <div class="form-row">
                        <div class="col-3">
                            <FormSelect
                                @change="setFilial($event)"
                                :attributes="{name: 'filial_id'}"
                                :options="branchesOptions"
                                v-model="orderInfoData.filial_id"
                            />
                        </div>
                        <div class="col-3">
                            <FormInput
                                key="order_id"
                                v-model="orderInfoData.order_id"
                                :attributes="{name:'order_id',placeholder: 'Номер заказа',}"
                            />
                        </div>
                        <div class="col-3">
                            <FormInput
                                key="incoming_id"
                                v-model="orderInfoData.incoming_id"
                                :attributes="{name:'incoming_id',placeholder: 'Номер ВО',}"
                            />
                        </div>
                    </div>
                    <div class="row-col">
                        <div class="col-12">
                            <Button @click.prevent="applyQuestionnaireOptions"
                                label='Сохранить'
                                :attributes="{class: (orderInfoData.filial_id) ? 'btn-green' : ''}"/>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</teleport>
