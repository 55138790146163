<div class="list menu-sidebar questionnaire-menu">
    <div class="list--wrapper--item">
        <label
            @click="btnHandler()"
            class="list--item"
        >
            <span class="icon--wrapper">
                <span class="icon film"></span>
            </span>
            <span class="list--item--name">
                Начать конференцию
            </span>
        </label>
    </div>
</div>

<ConferenceModal
    v-model:showModal="showModalConferenceLinks"
    :order-info="orderInfo"
    :result-code="resultCode"
/>
