<template src="../templates/order-info.html"></template>
<script>

import Button from '@f/Button';
import OrderInfoModal from './OrderInfoModal';

export default {
    name: 'OrderInfo',
    components: {
        OrderInfoModal,
        Button,
    },
    props: {
        orderInfo: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    emits: ['update:orderInfo'],
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        showModalHandler() {
            this.showModal = true;
        },
        changeOrderInfo(info) {
            this.$emit('update:orderInfo', {...this.orderInfo, ...info});
        }
    }
};
</script>
