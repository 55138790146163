<template src="../templates/kp-links.html"></template>
<script>

import {getKPData} from '@api/questionnaire';

export default {
    name: 'KpLinks',
    props: {
        resultCode: {
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            kpData: []
        };
    },
    async beforeCreate() {
        if (this.resultCode) {
            this.kpData = (await getKPData(this.resultCode))
                .map(item => ({...item, href: `${process.env.VUE_APP_HOST_URL}/kp/edit/${item.hash}`}));
        }
    },
};
</script>
