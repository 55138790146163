<div class="row c" v-if="news && news.length">
    <div class="news">
        <div class="news-head" v-on:click="toggleOpen">Новости</div>
        <div class="news-body" ref="newsBody" :style="{ maxHeight: height }">
            <ul class="news-items">
                <li class="news-item" v-for="newsItem in news">
                    <div class="news-item-date">{{ getCreateDate(newsItem) }}</div>
                    <div v-html="newsItem.news" />
                </li>
            </ul>
        </div>
    </div>
</div>
