<div :class="'default-column' + (options.class ? ' ' + options.class : '')">
    {{ title }}
    <div v-if="options.comment" class="comment" v-html="options.comment" />
    <tippy
        class="tooltip-icon"
        v-if="options.tooltip"
        :key="'countDetails-'+ options.tooltip.id"
        :content="options.tooltip.comment"
        allowHTML
        arrow
        arrowType="round"
        theme="light"
        placement="bottom"
    >
        <span class="icon--wrapper" data-id="" type="group"><span class="icon help-circle"></span></span>
    </tippy>
</div>
