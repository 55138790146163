<template src="../templates/selection-result.html"></template>
<script>

import FormCheckbox from '@f/Check';
import Selection from './Selection';

export default {
    name: 'SelectionResult',
    components: {
        Selection,
        FormCheckbox,
    },
    props: {
        mode: {
            type: [String],
            default() {
                return '';
            }
        },
        orderInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        questionnaireId: {
            type: [Number, String],
            default() {
                return null;
            }
        },
        questions: {
            type: [Array, Object],
            default() {
                return [];
            }
        },
        questionsSource: {
            type: [Array, Object],
            default() {
                return [];
            }
        },
        recalculate: {
            type: Boolean,
            default() {
                return false;
            }
        },
        selectedProducts: {
            type: Array,
            default() {
                return [];
            }
        },
        addSelectedProduct: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        removeSelectedProduct: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        calculateResult: {
            type: Object,
            default() {
                return {
                    products: []
                };
            }
        },
        scenarioRatings: {
            type: Object,
            default() {
                return null;
            }
        },
        questionnaire: {
            type: Object,
            default() {
                return null;
            }
        },
        calculateTitles: {
            type: Object,
            default() {
                return null;
            }
        },
        detailsCalculation: {
            type: Object,
            default() {
                return null;
            }
        },
        isProductPricesDisplayed: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    emits: [
        'update:recalculate',
        'update:selectedProducts',
        'update:calculateResult',
        'update:isProductPricesDisplayed',
    ],
    data() {
        return {
            productPricesDisplayed: this.isProductPricesDisplayed,
        };
    },
    watch: {
        recalculate: {
            handler(val, oldVal) {
                if (val === true) {
                    this.$emit('update:recalculate', false);
                }
            },
        },
        productPricesDisplayed() {
            this.$emit('update:isProductPricesDisplayed', this.productPricesDisplayed);
        }
    },
    methods: {
        setCalculateResult(result) {
            this.$emit('update:calculateResult', result);
        },
    }
};
</script>
