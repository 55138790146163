<teleport to="body" v-if="variables">
    <Modal
        v-if="displayFinancialPopup"
        @onOk="updateDisplayFinancialPopup(false)"
        @on-close="updateDisplayFinancialPopup(false)"
        :buttons="[]"
    >
        <template v-slot:header>
            <div class="title">Вопрос по финансированию</div>
        </template>
        <template v-slot:body>
            <form action="">
                <div class="modal-section">
                    <div class="questionnaire-pane mb-4">
                        <div class="header">
                            <label class="question">
                                <span class="icon expand"></span>
                                <span class="title h5" v-if="varQuestions['finance']">
                                    {{varQuestions['finance'].description}}
                                </span>
                            </label>
                        </div>
                        <div class="content">
                            <div class="answers">
                                <template v-for="fProduct in finProducts">
                                    <FormRadio
                                        v-if="getFinancialProduct(fProduct.code)"
                                        :label="fProduct.title"
                                        @click.prevent="setPotentialFinancialProduct(fProduct.code)"
                                        :attributes="{
                                            checked: (potentialFinancialProduct === fProduct.code),
                                        }"
                                    />
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="row-col">
                        <div class="col-12">
                            <FormButton
                                label="Применить"
                                :attributes="{class:['btn-green']}"
                                @click.prevent="setFinancialProduct();updateDisplayFinancialPopup(false)"
                            />
                            <FormButton
                                label="Отменить"
                                :attributes="{class:['btn-white']}"
                                @click.prevent="updateDisplayFinancialPopup(false)"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </Modal>
</teleport>
