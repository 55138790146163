import api from '../tools/api';

export const getActions = () => api.request(
    'assignment',
    'get-actions'
);
export const getTypes = () => api.request(
    'assignment',
    'get-types'
);
export const getAssignment = (subject, subject_id, options) => api.request(
    'assignment',
    'get-assignments',
    {subject, subject_id, options}
);

export const saveAssignment = (subject, subject_id, data, additionalData) => api.request(
    'assignment',
    'save-assignments',
    {
            subject, subject_id, data, additionalData
        }
);
