import api from '../tools/api';

/**
 * Создать/обновить заказ через API 1С
 * @param successHandler
 * @param errorHandler
 * @param params
 * @returns {Promise<unknown>}
 */
const saveOrder = ({successHandler = null, errorHandler = null, ...params}) => api
    .request(
        'external1c',
        'save-order',
        {...params},
        {},
        {
            resolve: successHandler,
            reject: errorHandler,
        }
    );

/**
 * Создать/сохранить заказ с обработчиками ошибок
 * @param data
 * @param onSuccess
 * @param onError
 * @returns {Promise<void>}
 */
export const save1COrder = async (data, onSuccess, onError) => {
    const responseHandler = (_, response) => {
        switch (response?.status) {
            case 'success': {
                const orderId = response?.data?.order_id;
                if (orderId) {
                    onSuccess(orderId);
                }
                break;
            }
            case 'error':
                onError(`1C. Ошибка: \n${response?.data}`);
                break;
            default:
                onError(`1C. Ошибка: ${response.toString()}`);
                break;
        }
    };

    await saveOrder({
        successHandler: responseHandler,
        errorHandler: responseHandler,
        ...data
    }).then().catch(
        e => {
            onError(`Заказ не передан в 1С.\nОшибка: ${e}.`);
        }
    );
};
