<div
    :id="`pane-${item.id}`"
    :data-variable-name="item.value_name"
    :class="`questionnaire-pane ${!!item?.answer?.length && 'ok'}`"
    :data-calculate="item.question_code !== ''"
>
    <input :id="item.id" type="checkbox" class="pane-min hide" v-bind="item.min">
    <div class="header">
        <label class="question" :for="item.id">
            <span class="icon expand"></span>
            <span class="title h5">{{ item.description }}</span>
        </label>
        <Button
            @click.prevent="importantHandler($event)"
            label="Важный"
            :class="{'active': item.important === 'true'}"
            :attributes="{class: ['btn-white', 'btn-sm','btn-important']}"
        />
    </div>
    <div v-if="item.answers && item.answers.length" class="content">
        <div class="answers">
            <template v-for="(answer, i) in item.answers">
                <component
                    :is="item.multiple ? 'Checkbox' : 'Radio'"
                    @change="clickToAnswer(answer.id)"
                    @click="clickToAnswerDelete($event, answer.id)"
                    :label="answer.description"
                    :attributes="{
                        id: 'ans' + answer.id,
                        value: answer.id,
                        checked: item?.answer?.includes(answer.id) || false,
                        'data-variable-value': answer.value,
                    }"
                />
            </template>
        </div>
        <div class="description">
            <template v-for="answer in item.answers">
                <div
                    v-if="((answer.comment.replace(/<\/?[^>]+(>|$)/g, '')).length > 0) && item?.answer?.includes(answer.id)"
                    v-html="answer.comment"
                    :id="`desc-ans${answer.id}`"
                    class="text"
                >
                </div>
            </template>
        </div>
    </div>
    <div v-else class="content">
        <Input
            v-model="item.answer"
            :attributes="{
                name: `question[${item.id}][answer][]`,
                class:'textarea',
                placeholder:'Введи ответ...'
            }"
        />
    </div>
    <div class="content-ok">
        <ul></ul>
    </div>
    <div class="footer">
        <div class="row-btn">
            <div class="readmore-btn">
                <Button @click.prevent="showQuestionDetails" v-if="item.comment" label="Примечание"
                    :attributes="{class:['btn-white','btn-sm','questionnaire-readmore']}"/>
            </div>
        </div>
        <div class="row">
            <div
                v-if="item.comment && showComment"
                v-html="item.comment"
                class="readmore-body mt-2 pl-1"
            >
            </div>
        </div>
    </div>
</div>

