<template src="../templates/conference-modal.html"></template>
<script>

import {
    getLinksForConferenceApi
} from '@api/offer-conference';


import Modal from '@c/Modal';
import Checkbox from '@f/Check';
import Button from '@f/Button';

export default {
    name: 'ConferenceModal',
    components: {
        Modal,
        Checkbox,
        Button,
    },
    props: {
        showModal: {
            type: Boolean,
            default() {
                return false;
            }
        },
        orderInfo: {
            type: Object,
            default() {
                return {
                    partner_code: null,
                    partner_contact: null,
                };
            }
        },
        resultCode: {
            type: String,
            default() {
                return null;
            }
        },
    },
    data() {
        return {
            offerConferenceLinks: {
                start_url: null,
                join_url: null,
            },
            offerConferenceLinksLoad: null,
            offerConferenceLinksIsTest: false,
        };
    },
    methods: {
        async getOfferConferenceLinks() {
            this.offerConferenceLinksLoad = true;
            this.offerConferenceLinks = await getLinksForConferenceApi(
                {
                    result_code: this.resultCode,
                    partner_code: this.orderInfo.partner_code,
                    is_test: this.offerConferenceLinksIsTest
                }
            );
            this.offerConferenceLinksLoad = false;
        }
    }
};
</script>
