<template src="../templates/order-info-modal.html"></template>
<script>

import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';

import Modal from '@c/Modal';
import Button from '@f/Button';
import FormSelect from '@f/Select';
import FormInput from '@f/Input';
import Filter from '@/entities/filter';

import eventBus from '@/entities/eventBus';

export default {
    name: 'OrderInfoModal',
    components: {
        Button,
        FormSelect,
        Modal,
        Multiselect,
        FormInput,
    },
    props: {
        showModal: {
            type: Boolean,
            default() {
                return false;
            }
        },
        orderInfo: {
            type: Object,
            default() {
                return {
                    filial_id: null,
                    order_id: null,
                    incoming_id: null,
                };
            }
        },
        changeOrderInfo: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    emits: ['update:showModal'],
    data() {
        return {
            isModalVisible: this.showModal,
            validationErrors: {},
            orderInfoData: {...this.orderInfo},
            partner: {},
            get globalFilter() {
                return new Filter();
            },
        };
    },
    beforeMount() {
        this.loadPartner();
        eventBus.$on('event-partner-selected', partner => {
            this.partner = partner;
        });
    },
    computed: {
        branches() {
            return this.$store.state.Catalogs.branches;
        },
        branchesOptions() {
            if (!this.branches) {
                return [];
            }

            return this.branches.map(item => (
                {
                    title: item.full_name,
                    value: item.id,
                }
            ));
        },
        contactInfo() {
            let result = null;
            if (Array.isArray(this.partner?.contacts)) {
                const contact = this.partner.contacts.find(c => c.id === this.partner.selectedContact);
                if (contact) {
                    result = `${contact.name} (${contact.position})`;
                }
            }

            return result;
        },
        isPartnerSelected() {
            return this.orderInfo.partner_code && this.orderInfo.partner_name && this.contactInfo;
        },
    },
    watch: {
        orderInfo: {
            async handler(val) {
                this.orderInfoData = {...val};
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        loadPartner() {
            const partner = this.globalFilter.getGroup('partner') || {};
            if (
                JSON.stringify(partner) !== JSON.stringify(this.partner)
            ) {
                this.partner = partner;
            }
        },
        setFilial(event) {
            sessionStorage.setItem('filial', event.target.value);
        },
        applyQuestionnaireOptions() {
            if (this.orderInfoData.filial_id) {
                this.changeOrderInfo({...this.orderInfoData, filial_id: parseInt(this.orderInfoData.filial_id, 10)});
                this.$emit('update:showModal', false);
            }
        },
        openPartnerModal() {
            eventBus.$emit('open-partner-modal');
        },
    }
};
</script>
