import api from '../tools/api';


export const getNewsForEdit = (questionnaire_id) => api.request(
	'questionnaire',
	'get-news-edit',
	{questionnaire_id, options: {all: true}}
);

export const getNews = (questionnaire_id) => api.request(
	'questionnaire',
	'get-news',
	{questionnaire_id}
);

export const updateNews = (news_id, data) => api.request(
	'questionnaire',
	'update-news',
	{news_id, data}
);

export const removeNews = (news_id) => api.request(
	'questionnaire',
	'remove-news',
	{news_id}
);


