<template src="./product-availability.html"></template>

<script>
import './product-availability.scss';

import Modal from '@c/Modal';

export default {
    name: 'ProductAvailability',
    components: {
        Modal,
    },
    props: {
        popupTitle: {
            type: String,
            default() {
                return 'Доступность товара';
            }
        },
        titles: {
            type: Object,
            default() {
                return null;
            }
        },
        currentBranchId: {
            type: Number,
            default() {
                return null;
            }
        },
        branches: {
            type: Array,
            default() {
                return null;
            }
        },
        availabilityDetails: {
            type: Object,
            default() {
                return null;
            }
        },
        viewAvailability: {
            type: Boolean,
            default() {
                return false;
            }
        },
        onToggleViewAvailability: {
            type: Function,
            default() {
                return () => {};
            }
        },
    },
    data() {
        return {
            selectedFilial: this.currentBranchId,
        };
    },
    computed: {
        columns() {
            const result = ['Филиал'];
            Object.keys(this.titles).forEach(key => {
                if (key !== 'availability') {
                    result.push(this.titles[key]);
                }
            });
            return result;
        },

        tableData() {
            let line = [];
            this.columns.forEach(title => {
                line.push(this.buildColumn(title, {class: 'title'}));
            });

            const result = [line];

            this.filialList.forEach(filial => {
                line = [
                    this.buildColumn(filial.full_name)
                ];
                let addFlag = false;
                Object.keys(this.titles).forEach(key => {
                    if (key !== 'availability') {

                        if (this.availabilityDetails[filial.id][key] > 0) {
                            addFlag = true;
                        }

                        if (
                            typeof this.availabilityDetails[filial.id] !== 'undefined'
                            && typeof this.availabilityDetails[filial.id][key] !== 'undefined'
                        ) {
                            line.push(
                                this.buildColumn(
                                    this.availabilityDetails[filial.id][key] > 0
                                        ? this.availabilityDetails[filial.id][key]
                                        : ''
                                )
                            );
                        } else {
                            line.push(this.buildColumn(''));
                        }
                    }
                });

                if (addFlag) {
                    result.push(line);
                }
            });
            return result;
        },
        filialList() {
            return [...this.branches].sort(a => (a !== this.currentBranchId ? 1 : -1));
        },
    },
    methods: {
        buildColumn(text, options) {
            return {
                ...{
                    text,
                    class: false,
                },
                ...options
            };
        },
        log() {
            console.log(this.titles, 'titles');
            console.log(this.currentBranchId, 'currentBranchId');
            console.log(this.branches, 'branches');
            console.log(this.availabilityDetails, 'availabilityDetails');
            console.log('------------------');
            return '';
        },
    },
};
</script>
