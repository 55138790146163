<div class="partners breadcrumb row c d-flex justify-content-between align-items-center">
    <div class="breadcrumb-items">
        <span class="breadcrumb-item">Код: {{orderInfo.partner_code}}</span>
        <span class="breadcrumb-item">Контрагент: {{orderInfo.partner_name}}</span>
        <span class="breadcrumb-item">Контакт: {{orderInfo.partner_contact}}</span>
        <span class="breadcrumb-item">№ заказа: {{orderInfo.order_id}}</span>
        <span class="breadcrumb-item">№ ВО: {{orderInfo.incoming_id}}</span>
    </div>
    <Button @click="showModalHandler" :label="'<span>✎</span> Изменить'"></Button>
</div>
<OrderInfoModal
    v-model:showModal="showModal"
    :order-info="orderInfo"
    :change-order-info="changeOrderInfo"
/>
