<template src="./column-default.html"></template>
<script>

import VueTippy from 'vue-tippy';

export default {
    name: 'ColumnDefault',
    components: {
        VueTippy,
    },
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        title() {
            return this.column.name;
        },
        options() {
            return this.column.options;
        },
    }
};

</script>
