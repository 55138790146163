import api from '../tools/api';

export const createOffer = params => api.request(
    'offer',
    'create-offer',
    params
);

export const getOfferData = params => api.request(
    'offer',
    'get-offer-data',
    params
);
