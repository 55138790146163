<template src="./answer-points.html" />
<script>

import Stars from '@c/Stars';

export default {
    name: 'ColumnAnswerPoints',
    components: {
        Stars,
    },
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        percent() {
            return (parseFloat(this.column.name) / 2) * 100;
        }
    }
};

</script>
