<template src="./column-title.html"></template>
<script>

export default {
    name: 'ColumnTitle',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        title() {
            return this.column.name;
        }
    }
};

</script>
