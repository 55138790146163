<template src="../templates/news.html"></template>
<script>

import {getNews} from '@api/questionnaire-news';

export default {
    name: 'News',
    props: {
        questionnaireId: {
            type: [String, Number],
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            news: null,
            height: '0px',
        };
    },
    async beforeCreate() {
        this.news = await getNews(this.questionnaireId);
    },
    methods: {
        toggleOpen(event) {
            event.target.classList.toggle('open');
            if (event.target.classList.contains('open')) {
                this.height = `${event.target.nextSibling.children[0].offsetHeight}px`;
            } else {
                this.height = '0px';
            }
        },
        getCreateDate(newsItem) {
            const createDate = new Date(Date.parse(newsItem.create_date));
            return createDate.toLocaleString('ru', {day: 'numeric', month: 'long', year: 'numeric'});
        },
    },
};
</script>
