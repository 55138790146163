<div class="preloader" v-if="preloader"></div>
<div :class="'form-group' + groupClass" v-else>
    <label v-if="label" :class="labelClassPrep" :for="attributes.id">{{label}}</label>
    <DatePicker
        
        mode="dateTime"
        :minute-increment="5"
        is24hr

        :input-debounce="5000000"
        
        v-model="value"
        :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DD HH:MM'
        }"
    >
    
        <template v-slot="{ inputValue, inputEvents }">
            <span class="date-range-label">
                <FormButton
                    type="a"
                    :attributes="{class:['date-range-label-start', 'text-blue', 'btn-link', 'no-bg']}"
                    :label="inputValue || 'Дата'"
                    v-on="inputEvents"
                />
                
                <span
                    class="date-range-label-reset"
                    style="padding: 0 10px; font-weight: bold; color: #ff0000; cursor: pointer;"
                    v-if="inputValue"
                    @click="value = ''"
                >x</span>
            </span>
        </template>
    </DatePicker>
</div>
