<template src="../templates/questions.html"></template>
<script>

import Question from './Question';

export default {
    name: 'Questions',
    components: {
        Question
    },
    props: {
        questions: {
            type: Array,
            default() {
                return [];
            }
        },
        optionsQuestionnaireCalculation: {
            type: Array,
            default() {
                return [];
            }
        },
    },
};
</script>
