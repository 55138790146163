<teleport to="body">
    <modal
        v-if="viewAvailability"
        :buttons="[]"
        @onOk="onToggleViewAvailability"
        @on-close="onToggleViewAvailability"
    >
        <template v-slot:header>
            <h2>{{popupTitle}}</h2>
        </template>
        <template v-slot:body>
            <div  class="availability-container">
                <table>
                    <tr v-for="line in tableData">
                        <td v-for="column in line">
                            <div :class="column.class">{{column.text}}</div>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="availability-container" v-if="false">
                <template v-for="filial in filialList">
                    <div :class="'filial-container ' + ((filial.id === selectedFilial) ? ' current' : '')">
                        <template v-if="availabilityDetails[filial.id]">
                            <div class="filial-name" @click="selectedFilial = filial.id">
                                {{filial.full_name}}
                            </div>
                            <div class="availability-counts">
                                <table>
                                    <template v-for="(count, detailId) in availabilityDetails[filial.id]">
                                        <tr v-if="detailId !== 'availability'" class="availability-item">
                                            <td><span class="availability-title">{{titles[detailId]}}</span></td>
                                            <td><span class="availability-value">{{count}}</span></td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
    </modal>
</teleport>
