<template src="../../templates/selection/finance-question.html"></template>
<script>

import FormRadio from '@f/Radio';
import FormButton from '@f/Button';
import Modal from '@c/Modal';

export default {
    name: 'FinanceQuestions',
    components: {
        FormButton,
        FormRadio,
        Modal,
    },
    props: {
        variables: {
            type: Array,
            default() {
                return null;
            }
        },
        questions: {
            type: Object,
            default() {
                return null;
            }
        },
        priceList: {
            type: Array,
            default() {
                return null;
            }
        },
        displayFinancialPopup: {
            type: Boolean,
            default() {
                return false;
            }
        },
        onSelectFinanceProduct: {
            type: Function,
            default() {
                return () => {};
            }
        }
    },
    emits: ['update:displayFinancialPopup'],
    data() {
        return {
            selectedFinancialProduct: null,
            potentialFinancialProduct: null,
            finProducts: [
                {
                    code: 'leasing',
                    title: 'Лизинг'
                },
                {
                    code: 'credit',
                    title: 'Кредит'
                },
                {
                    code: 'installment',
                    title: 'Рассрочка'
                },
                {
                    code: 'price',
                    title: '100% предоплата'
                },
            ]
        };
    },
    computed: {
        varQuestions() {
            const result = {};
            Object.values(this.questions).forEach(item => {
                if (item.value_name) {
                    result[item.value_name] = item;
                }
            });
            return result;
        }
    },
    watch: {
        selectedFinancialProduct() {
            // TODO избавиться от работы с DOM
            if (this.selectedFinancialProduct) {

                const form = document.querySelector('#questionnairePanes');
                const financeBlock = form.querySelector('.questionnaire-pane[data-variable-name=finance]');

                if (financeBlock) {
                    const all = financeBlock.querySelectorAll('.form-check input[checked=checked]');
                    if (all.length) {
                        // eslint-disable-next-line array-callback-return,no-param-reassign
                        all.map(checkbox => { checkbox.checked = false; });
                    }

                    // TODO 10 clickToAnswer
                    const toSelect = financeBlock.querySelector(`[data-variable-value=${this.selectedFinancialProduct}]`);
                    if (toSelect) {
                        document.getElementById(`ans${toSelect.value}`).click();
                    }
                    //data-variable-value
                }
            }
        }
    },
    methods: {
        getFinancialProduct(code) {
            return this.variables.includes(code) && this.priceList.includes(code);
        },
        setFinancialProduct() {
            this.selectedFinancialProduct = this.potentialFinancialProduct;
            this.onSelectFinanceProduct(this.potentialFinancialProduct);
        },
        updateDisplayFinancialPopup(display) {
            this.$emit('update:displayFinancialPopup', display);
        },
        setPotentialFinancialProduct(financeType) {
            this.potentialFinancialProduct = financeType;
        },
    }
};
</script>
