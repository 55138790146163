<template src="./templates/cart-form.html" />

<script>
import Filter from '@/entities/filter';
import eventBus from '@/entities/eventBus';
import Loader from '@c/Loader';
import {mapGetters} from 'vuex';
import Price from '@/entities/price';
import Counter from '@c/Counter';
import {REQUIRED_PRODUCTS_COUNT_FOR_CATEGORY} from '../../constants';

export default {
    name: 'CartForm',
    components: {
        Loader,
        Counter,
    },
    props: {
        selectedProducts: {
            type: Array,
            default() {
                return [];
            }
        },
        orderInfo: {
            type: Object,
            default() {
                return {};
            }
        },
        sendKpHandler: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        leavePageHandler: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        addToOrderHandler: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        saveOrderHandler: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        removeProductHandler: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        leavePageResolver: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        orderChanged: {
            type: Boolean,
            default() {
                return false;
            }
        },
        pageLeaving: {
            type: Boolean,
            default() {
                return false;
            }
        },
        calculateResult: {
            type: Object,
            default() {
                return {
                    products: []
                };
            }
        },
        questionnaire: {
            type: Object,
            default() {
                return {
                    id: null,
                    name: null,
                };
            }
        },
        expanded: {
            type: Boolean,
            default: true,
        }
    },
    emits: ['update:orderInfo', 'update:orderChanged', 'open-partner-modal'],
    data() {
        return {
            formExpanded: true,
            products: [],
            get globalFilter() {
                return new Filter();
            },
            preloaderSave1COrderName: 'external1c_save-order',
            preloaderSaveQuestionnaireResultName: 'questionnaire_set-questionnaire-result',
            autoAddedProducts: false,
        };
    },
    computed: {
        // мержим в computed геттеры из стора
        ...mapGetters({
            preloaders: 'preloaders',
        }),
        preloaderSave1COrder() {
            return this.preloaders[this.preloaderSave1COrderName];
        },
        preloaderSaveQuestionnaireResult() {
            return this.preloaders[this.preloaderSaveQuestionnaireResultName];
        },
        productsCount() {
            return this.products.length;
        },
        productsFormatted() {
            return this.products.reduce((acc, product) => {
                const result = {...acc};

                const key = `_${product.questionnaire?.id}`;
                if (typeof result[key] === 'undefined') {
                    result[key] = {
                        questionnaire: {},
                        products: [],
                    };
                }

                result[key].questionnaire = {id: product.questionnaire.id, name: product.questionnaire.name};
                result[key].products.push({...product});

                return result;
            }, {});
        },
        resultCode() {
            // код результата нужен будет только для определения общих данных о заказе (номер, во, контрагент, к. лицо)
            // поэтому берем его из последнего продукта
            return this.products[this.products.length - 1]?.questionnaire_result;
        },
        priceTool() {
            return new Price();
        },
        /**
         * Если выбранных товаров текущей категории меньше 3, формируются доп товары для добавления
         * @returns {[]}
         */
        extraProductsForSave() {
            let result = [];
            const currentSelectedProducts = this.products.filter(p => p.questionnaire.id === this.questionnaire.id);
            const priceType = currentSelectedProducts[0].price.type;

            if (currentSelectedProducts.length < REQUIRED_PRODUCTS_COUNT_FOR_CATEGORY) {
                const currentProductCodes = currentSelectedProducts.map(p => p.external_id);
                const availableProductsToAdd = this.calculateResult.products.filter(p => !currentProductCodes.includes(p.external_id) && p.prices[priceType]?.local_price > 0);
                const productsToAddCount = REQUIRED_PRODUCTS_COUNT_FOR_CATEGORY - currentSelectedProducts.length;
                result = availableProductsToAdd.slice(0, productsToAddCount).map(p => (
                        {
                            ...p,
                            count: 0,
                            questionnaire_result: this.resultCode,
                            questionnaire: {
                                id: this.questionnaire.id,
                                name: this.questionnaire.name,
                            },
                            payment_type: priceType,
                            price: p?.prices?.[priceType],
                            originalPrice: p?.prices?.price?.details?.priceWithoutCommissioning?.total
                                || p?.prices?.price?.price,
                            commissioning: p.prices?.commissioning?.price,
                        }));
            }

            return result;
        },
    },
    watch: {
        products: {
            handler(val) {
                this.globalFilter.setGroup('multioffer_cart', val);
            },
        },
        orderInfo: {
            handler(val) {
                this.globalFilter.setGroup('multioffer_order', val);
            },
            deep: true,
        },
        pageLeaving: {
            handler(val) {
                if (val === true) {
                    const resolver = this.leavePageResolver;
                    this.leaveOrderHandler(resolver);
                }
            },
        },
        expanded: {
            handler(val) {
                this.formExpanded = val;
            },
        },
    },

    beforeMount() {
        const partner = this.globalFilter.getGroup('partner');
        const order = this.globalFilter.getGroup('multioffer_order');
        if (partner?.external_id === order?.partner_code) {
            this.restoreCart();
        }
    },

    methods: {
        toggleForm() {
            this.formExpanded = !this.formExpanded;
        },
        removeProduct(questionnaireId, externalId) {
            const resultCode = this.products.find(
                v => v?.external_id === externalId && v?.questionnaire?.id !== questionnaireId
            )?.questionnaire_result;

            this.products = this.products.filter(
                v => !((v?.external_id === externalId) && (v?.questionnaire?.id === questionnaireId))
            );
            this.removeProductHandler(this.products.filter(p => p.questionnaire_result === resultCode), resultCode);
        },
        async addSelectedProducts() {
          // избегаем повторного добавления товаров в корзину
          const productsExternalIds = this.products.map(v => v.external_id);
          const selectedProducts = [...this.selectedProducts].filter(v => !productsExternalIds.includes(v.external_id));
          await this.addProducts(selectedProducts);
        },
        async addProducts(productsToAdd) {
            // первый финансовый продукт становится основным, добавлять товары с другим финансированием нельзя
            const firstSelectedPaymentType = this.products?.[0]?.payment_type || productsToAdd?.[0]?.payment_type;
            let stopAdding = false;

            if (!productsToAdd.length) {
                window.alert('Не выбраны товары для добавления либо они уже добавлены в заказ!');
                stopAdding = true;
            } else {
                productsToAdd.forEach(v => {
                    if (!this.isSimilarPaymentTypes(v?.payment_type, firstSelectedPaymentType) && !stopAdding) {
                        window.alert('Нельзя добавлять в заказ товары с другим типом финансирования. Создайте для этого новый заказ.');
                        stopAdding = true;
                    }
                });
            }

            if (!stopAdding) {

                // Сохранение с вызовом.....
                const resultCode = await this.addToOrderHandler([
                    ...productsToAdd
                ]);

                // При инициализации страницы, если в url был обнаружен товар,
                // он добавляется в selectedProducts до калькуляции с недостаточным кол-вом данных (наименование, например).
                // Дополняем массив недостающими данными (финпродукт и прайс остаются прежними, если пришли из урла)
                productsToAdd = productsToAdd.map(selProduct => {
                    const prod = this.calculateResult.products.find(p => p.external_id === selProduct.external_id);
                    return {
                        ...prod,
                        ...selProduct,
                    };
                });

                // Магия детектед: тут на продуктах висит вотчер, если мы изменяем значения, то вотчер их сохраняет глобально
                // и только после этого товары становятся доступны на фронте
                this.products = [
                    ...this.products,
                    ...productsToAdd.map(p => ({...p, questionnaire_result: resultCode}))
                ];

                this.formExpanded = true;
            }
        },
        isSimilarPaymentTypes(type1, type2) {
            const isEqual = type1 === type2;
            let isSimilar = false;
            this.priceTool.similarPriceTypes.forEach(types => {
                if (types.includes(type1) && types.includes(type2)) {
                    isSimilar = true;
                }
            });

            return isEqual || isSimilar;
        },
        async saveOrder(e = null, callback = null) {
            // если товаров текущей категории меньше 3, уведомляем о добавлении доп товаров
            if (this.extraProductsForSave.length && !this.autoAddedProducts) {
                const extraProductsNames = this.extraProductsForSave.map(p => p.name);
                const msg = `В заказ автоматически добавлены следующие товары:\n${extraProductsNames.join(',\n')}`;
                alert(msg);
                this.autoAddedProducts = true;
                await this.addProducts(this.extraProductsForSave);
                return;
            }
            // выбираем все коды результатов
            const resultCodes = this.products.reduce((acc, product) => {
                const arr = [...acc];
                arr.push(product.questionnaire_result);
                return arr;
            }, []);
            await this.saveOrderHandler(this.products, [...new Set(resultCodes)], callback);
        },
        async sendKp() {
            await this.saveOrder(null, async () => {
                await this.sendKpHandler(this.products, this.resultCode);
            });
        },
        newOrder() {
            this.leaveOrderHandler((status = true) => {
                if (status) {
                    this.products = [];
                    this.$emit('update:orderInfo', {});
                    this.$emit('update:orderChanged', false);

                    this.globalFilter.setGroup('partner', {});
                    eventBus.$emit('event-partner-selected', {});

                    this.$router.push({name: 'PartnerList'});
                }
            }, 'Вы создаёте новый заказ, не сохранив текущий! Хотите сохранить изменения?');

        },

        async leaveOrderHandler(resolver, confirmationText = null) {
            if (this.orderChanged && this.orderInfo.partner_code) {
                const answer = window.confirm(confirmationText || 'Вы закрываете опросник текущего контрагента! Хотите сохранить изменения?');
                if (answer) {
                    await this.leavePageHandler(this.products);
                    await this.saveOrder(null, () => resolver());
                } else {
                    resolver(false);
                }
            } else {
                resolver();
            }
        },

        restoreCart() {
            const multiofferCart = this.globalFilter.getGroup('multioffer_cart', []);
            const multiofferOrder = this.globalFilter.getGroup('multioffer_order');

            this.products = [...multiofferCart];
            this.$emit('update:orderInfo', multiofferOrder);
            // это обновление orderInfo не должно считаться изменением от пользователя
            this.$emit('update:orderChanged', false);
        },
        setProductCount(productCode, count) {
            const currentProduct = this.products.find(p => p.external_id === productCode);
            if (currentProduct) {
                currentProduct.count = count;
            }
        },
    },
};
</script>

<style src="./cart-form.scss" lang="scss"></style>
