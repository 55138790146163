<template src="../templates/question.html"></template>
<script>

import Button from '@f/Button';
import Checkbox from '@f/Check';
import Input from '@f/Input';
import Radio from '@f/Radio';

export default {
    name: 'Question',
    components: {
        Button,
        Checkbox,
        Input,
        Radio,
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            }
        },
        filterCalculatedQuestionnaire: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    emits: ['update:item'],
    data() {
        return {
            showComment: false,
            current: 0
        };
    },
    methods: {
        importantHandler() {
            this.$emit('update:item', {
                ...this.item,
                important: ((this.item.important === 'false' || !this.item.important) ? 'true' : 'false')
            });
        },
        clickToAnswer(answerId) {
            this.current = answerId;
            const selectedAnswers = this.item.answer || [];

            this.$emit('update:item', {
                ...this.item,
                // eslint-disable-next-line no-nested-ternary
                answer: !!this.item.multiple
                    ? selectedAnswers.includes(answerId)
                        ? [...selectedAnswers].filter(v => v !== answerId)
                        : [...selectedAnswers, answerId]
                    : [answerId]
            });

        },
        clickToAnswerDelete(e, answerId) {
            if (e.target.type === 'radio' && answerId === this.current) {
                this.cancelQuestionSelection();
            }
        },
        cancelQuestionSelection() {
            this.current = 0;
            this.$emit('update:item', {
                ...this.item,
                answer: []
            });
        },
        showQuestionDetails() {
            this.showComment = !this.showComment;
        }
    }
};
</script>
