<template src="./column-property.html" />
<script>

export default {
    name: 'ColumnProperty',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        item() {
            return this.column.data;
        }
    },
    methods: {
        getColor(points) {
            let lineColor = '';
            switch (true) {
                case points > 1:
                    lineColor = 'green';
                    break;
                case points >= 0:
                    lineColor = 'orange';
                    break;
                default:
                    lineColor = 'red';
            }
            return lineColor;
        },
        formatText(text) {
            switch (true) {
                case text === true || text === 'true':
                    return 'Да';
                case text === false || text === 'false':
                    return 'Нет';
                default:
                    return text;
            }
        },
    }
};

</script>
