<teleport to="body">
    <Modal
        v-if="showModal"
        @onOk="$emit('update:showModal', false)"
        :close-button="false"
        :buttons="[]"
    >

        <template v-slot:body>

            <div class="modal-section modal-section-header">
                <h3 class="h3">Получение ссылок для подключения к конференции</h3>
                <span @click="$emit('update:showModal', false)" class="danger-block-text-icon icon--wrapper">
                    <span class="icon close clickable"></span>
                </span>
            </div>

            <form v-if="orderInfo.partner_code && orderInfo.partner_contact">
                <div class="modal-section">

                    <div v-if="!offerConferenceLinks || !offerConferenceLinks.start_url" class="form-row">
                        <div class="col-3">
                            <Checkbox
                                v-model="offerConferenceLinksIsTest"
                                label="Тест-сделка"
                            />
                        </div>
                    </div>
                    <div v-else>
                        <p>
                            Ссылка для менеджера:
                            <a :href="offerConferenceLinks.start_url" target="_blank">
                                {{ offerConferenceLinks.start_url }}
                            </a>
                        </p>
                        <p>
                            Ссылка для клиента:
                            <a :href="offerConferenceLinks.join_url" target="_blank">
                                {{ offerConferenceLinks.join_url }}
                            </a>
                            <span style="color: #6c757d">
                                (активна только после старта конференции менеджером по своей ссылке)
                            </span>
                        </p>

                        <hr class="silver my_15">

                        <p class="text-red mb-1">Обратите внимание, по ссылке менеджера начать конференцию можно только 1 раз.</p>

                        <p class="mb-1">Если вы начали конференцию, затем по каким-либо причинам завершили её и хотите снова подключиться,
                            нужно запросить новую ссылку: по одной и той же ссылке начинать конференцию нельзя.</p>

                        <p class="mb-1">После перехода по полученой ссылке начнётся обратный отсчёт 5 минут,
                            в течение которых нужно начать конференцию непосредственно в Zoom.</p>
                    </div>
                    <div v-if="!offerConferenceLinks || !offerConferenceLinks.start_url" class="row-col">
                        <div class="col-12">
                            <Button
                                @click.prevent="getOfferConferenceLinks"
                                label='Получить ссылки'
                                :preloader="offerConferenceLinksLoad"
                                :attributes="{class: 'btn-green'}"
                            />
                        </div>
                    </div>
                </div>
            </form>
            <div v-else class="modal-section">
                <div class="danger-block">
                    <span class="danger-block-text-icon icon--wrapper">
                        <span class="icon exclamation"></span>
                    </span>
                    <span class="danger-block-text">
                        Нет данных о контрагенте
                    </span>
                </div>
                <p>
                    Для того, чтобы получить ссылки на конференцию, нужно внести данные о контрагенте и контактом лице.
                </p>
            </div>

        </template>
    </Modal>
</teleport>
