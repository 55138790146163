<template src="../templates/sidebar.html"></template>
<script>

import BoardMini from '@c/BoardMini';
import Button from '@f/Button';
import List from '@c/List';
import Assignment from '@c/Assignment';
import ConferenceRunBtn from './ConferenceRunBtn';
import SidebarRight from '../../../layouts/default/sidebarRight';

export default {
    name: 'SideBar',
    components: {
        BoardMini,
        List,
        SidebarRight,
        Button,
        Assignment,
        ConferenceRunBtn,
    },
    props: {
        questionnaireResultId: {
            type: [String, null],
            default() {
                return null;
            }
        },
        shortList: {
            type: Array,
            default() {
                return [];
            }
        },
        modelLines: {
            type: Array,
            default() {
                return [];
            }
        },
        questionnaireSavedEventName: {
            type: String,
            default() {
                return null;
            }
        },
        questionnaire: {
            type: Object,
            default() {
                return {};
            }
        },
        questionnaireProgress: {
            type: String,
            default() {
                return '';
            }
        },
        timeFormatted: {
            type: String,
            default() {
                return '';
            }
        },
        orderInfo: {
            type: Object,
            default() {
                return null;
            }
        },
    },
    emits: ['update:questionnaire'],
    data() {
        return {
            news: null,
            height: '0px',
        };
    },
    watch: {
        questionnaire: {
            handler(val) {
                this.$emit('update:questionnaire', val);
            },
        },
    },
    methods: {
        clickToNavBar(id, event) {
            event.stopPropagation();

            const pane_id = event.target.getAttribute('pane_id');
            if (pane_id) {
                const el = document.getElementById(pane_id);
                const yOffset = -200;
                const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }

            // saveEvent({
            //     type: 'crm_right_navigator',
            //     data: event.target.innerText
            // });
        },

    }
};
</script>
