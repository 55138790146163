<template src="./templates/index.html"></template>

<script>
import './styles.scss';
import Loader from '@c/Loader';
import Counter from '@c/Counter';
import {PRICE_TYPE_PREPAYMENT, PRICE_TYPE_PRICE} from '@/constants';

export default {
    name: 'OfferCartForm',
    components: {
        Loader,
        Counter,
    },
    props: {
        offer: {
            type: Object,
            required: true,
        },
        selectedProducts: {
            type: Array,
            required: true,
        },
        saveQuestionnaireResultHandler: {
            type: Function,
            required: true,
        },

        saveOrderHandler: {
            type: Function,
            required: true,
        },
        createOfferHandler: {
            type: Function,
            required: true,
        },
        resultCode: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            products: [],
            productsToAdd: [],
            productsToDelete: [],
            paymentType: '',
            productGroups: {},
            savedQuestionnaireProductIds: null,
            productsFor1c: [],
            productsForKp: [],
            isLoaded: false,
            newResultCode: null,
            isSelectedProductsInited: false,
            isOpen: true,
        };
    },
    computed: {
        savedOfferProductIds() {
            return this.products.map(p => p.id);
        },
        hostUrl() {
            return process.env.VUE_APP_HOST_URL;
        },
        offerManagerId() {
            return this.offer.managers[0] ?? null;
        },
        currentOfferHash() {
            return this.offer?.hash;
        },
        is1cOrder() {
            return !/WWW-*/.test(this.offer.offer_id);
        },
    },
    watch: {
        selectedProducts: {
            handler(selectedProducts) {
                const selectedProductIds = selectedProducts.map(product => product.external_id);
                this.productsToAdd = [];
                this.productsToDelete = [];

                this.savedQuestionnaireProductIds.forEach(productId => {
                    if (!selectedProductIds.includes(productId) && this.savedQuestionnaireProductIds.includes(productId)) {
                        this.productsToDelete.push(productId);
                    }
                });

                const errors = [];
                selectedProducts.forEach(product => {
                    if (product.payment_type !== this.paymentType) {
                        errors.push('Нельзя добавить товар с другим типом оплаты');
                    } else if (this.savedOfferProductIds.includes(product.external_id) && !this.productsToDelete.includes(product.external_id)) {
                        errors.push('Товар уже добавлен в оферту');
                    } else if (!this.savedQuestionnaireProductIds.includes(product.external_id)) {
                        this.productsToAdd.push(product.external_id);
                    }
                });

                errors.forEach(error => {
                    console.log(error);
                });

                this.getProductsForSave();
            },
            deep: true,
        },
    },
    mounted() {
        this.products = this.offer.products;
        this.initSavedQuestionnaireProductIds();
        this.sortProductsByCategory();

        this.paymentType = this.offer.payments[0].type;
        if (this.paymentType === PRICE_TYPE_PREPAYMENT) {
            this.paymentType = PRICE_TYPE_PRICE;
        }

        this.isLoaded = true;
    },
    methods: {
        findProduct(productId) {
            let product = this.selectedProducts.find(p => p.external_id === productId);
            if (!product) {
                product = this.products.find(p => p.id === productId);
            }

            return product;
        },
        getProductName(productId) {
            const product = this.findProduct(productId);
            let result = null;

            if (product) {
                result = product.name;
            }

            return result;
        },
        async updateOffer() {
            this.isLoaded = false;
            this.newResultCode = await this.saveQuestionnaireResultHandler([
                ...this.selectedProducts
            ], this.resultCode);

            this.getProductsForSave();

            const isOrderSaved = this.is1cOrder ? await this.saveOrderHandler(this.productsFor1c) : true;
            if (isOrderSaved) {
                const offerHash = await this.createOfferHandler(this.productsForKp, this.resultCode, false);
                this.goToOfferEditor(offerHash);
            } else {
                this.isLoaded = true;
            }
        },
        formatProductsFor1c(products) {
            return products.map(product => {
                const productCode = product.external_id ? product.external_id : product.id;
                // eslint-disable-next-line no-prototype-builtins
                const price = product.hasOwnProperty('price') ? product.price.local_price : product.prices.price;
                const originalPrice = product.originalPrice ? product.originalPrice : product.prices.default_price;
                const commissioning = price === originalPrice ? 0 : 1;
                const count = product?.prices?.count ?? 1;

                return {
                    product_code: productCode,
                    payment_type: this.paymentType,
                    price: price,
                    originalPrice: originalPrice,
                    commissioning: commissioning,
                    count: count,
                };
            });
        },
        formatProductsForKp(products) {
            return products.map(product => {
                const prices = product?.prices ?? {
                    price: product?.price?.local_price,
                    default_price: product?.originalPrice,
                    commissioning: product?.commissioning,
                    commissioning_total: product?.commissioning,
                    sale: false,
                };

                const productId = product?.external_id ?? product.id;
                let questionnaireResultId = product?.questionnaire_result_id;
                if (this.productsToAdd.includes(productId) || this.savedQuestionnaireProductIds.includes(productId)) {
                    questionnaireResultId = this.newResultCode;
                }

                return {
                    ...product,
                    external_id: productId,
                    payment_type: this.paymentType,
                    prices: prices,
                    originalPrice: prices.default_price,
                    commissioning: prices.commissioning,
                    default_commissioning: product.init_commissioning,
                    questionnaire_result: questionnaireResultId,
                    count: product?.prices?.count ?? 1,
                };
            });
        },
        sortProductsByCategory() {
            this.products.forEach(product => {
                const {categoryId, categoryName} = product;

                if (!this.productGroups[categoryId]) {
                    this.productGroups[categoryId] = {
                        name: categoryName,
                        products: [],
                        isEditing: false,
                    };
                }

                if (this.savedQuestionnaireProductIds.includes(product.id)) {
                    this.productGroups[categoryId].isEditing = true;
                }

                this.productGroups[categoryId].products.push(product);
            });

            this.productGroups = Object.values(this.productGroups);
        },
        initSavedQuestionnaireProductIds() {
            this.savedQuestionnaireProductIds = this.selectedProducts.map(product => product.external_id);
        },
        getProductsForSave() {
            let result = [];
            const savedProducts = [...this.products];
            savedProducts.forEach(savedProduct => {
                if (!this.productsToDelete.includes(savedProduct.id)) {
                    result.push(savedProduct);
                }
            }, []);

            const productsToAdd = this.productsToAdd.map(productId => this.findProduct(productId));

            result = [
                ...result,
                ...productsToAdd,
            ];

            this.productsFor1c = this.formatProductsFor1c(result);
            this.productsForKp = this.formatProductsForKp(result);

            return result;
        },
        deleteFromOffer(productCode) {
            if (!this.productsToDelete.includes(productCode)) {
                this.productsToDelete.push(productCode);
            }
        },
        removeFromToAdd(productCode) {
            if (this.productsToAdd.includes(productCode)) {
                this.productsToAdd = this.productsToAdd.filter(product => product !== productCode);
            }
        },
        removeFromToDelete(productCode) {
            if (this.productsToDelete.includes(productCode)) {
                this.productsToDelete = this.productsToDelete.filter(product => product !== productCode);
            }
        },
        goToOfferEditor(hash = null) {
            const offerHash = hash ?? this.currentOfferHash;
            const url = `${this.hostUrl}/offer/${offerHash}`;
            window.location.replace(url);
        },
        toggleOpen() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

