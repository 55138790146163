<template src="./column-feature.html"></template>
<script>

export default {
    name: 'ColumnFeature',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        title() {
            let result = '';

            switch (this.column.data.type) {
                case 'Range':
                    result = `${this.column.data.first_value}-${this.column.data.second_value}`;
                    break;
                case 'Boolean':
                    result = this.column.data.value ? 'Да' : ' Нет';
                    break;
                default:
                    result = this.column.data.value;
            }
            return result;
        },
    }
};

</script>
