<template src="../templates/conference-run-btn.html"></template>

<script>

import ConferenceModal from './ConferenceModal';

export default {
    name: 'ConferenceRunBtn',
    components: {
        ConferenceModal,
    },

    props: {
        orderInfo: {
            type: Object,
            default() {
                return {
                    partner_code: null,
                    partner_contact: null,
                };
            }
        },
        resultCode: {
            type: String,
            default() {
                return null;
            }
        },
    },

    data() {
        return {
            showModalConferenceLinks: false,
        };
    },

    methods: {
        btnHandler() {
            this.showModalConferenceLinks = true;
        }
    },
};
</script>
