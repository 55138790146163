<SidebarRight v-if="shortList">
    <div class="header">
        <BoardMini :title="timeFormatted" :subtitle="questionnaireProgress"/>

        <div v-for="link in modelLines">
            <a :href="link.url" target="_blank">{{link.name}}</a>
        </div>
    </div>
    <div class="content">
        <List
            @click="clickToNavBar"
            :params="{class:'menu-sidebar questionnaire-shortlist',type:'checkbox'}"
            :items="shortList"
        />
    </div>
    <div class="footer questionnaire-comment">

        <Assignment
            source="questionnaire_result"
            :source-id="questionnaireResultId"
            :save-event-name="questionnaireSavedEventName"
            :additional-data="{
                order_code: orderInfo.order_id,
                partner_code: orderInfo.partner_code,
                partner_contact: orderInfo.partner_contact,
                partner_contact_ref: orderInfo.partner_contact_ref
            }"
        />

        <ConferenceRunBtn :order-info="orderInfo"/>

    </div>
</SidebarRight>
