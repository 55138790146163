<Selection
    :mode="mode"
    :questionnaireOptions="orderInfo"
    :questions="questions"
    :questionsSource="questionsSource"
    :questionnaire-id="questionnaireId"
    :questionnaire="questionnaire"
    :scenario-ratings="scenarioRatings"
    :recalculate="recalculate"
    :selected-products="selectedProducts"
    :calculate-result="calculateResult"
    :calculate-titles="calculateTitles"
    :details-calculation="detailsCalculation"
    :add-selected-product="addSelectedProduct"
    :remove-selected-product="removeSelectedProduct"
    :set-calculate-result="setCalculateResult"
    v-model:is-product-prices-displayed="productPricesDisplayed"
>
    <!--        <template v-slot:loader>-->
    <!--            <Loader state="fixed" v-if="preloaderCalculate" :minHeight="199" />-->
    <!--        </template>-->
</Selection>
<div class="row mt-3">
    <FormCheckbox
        @change="/*filterCalculatedQuestionnaire*/()=>{}"
        label="Влияет на подбор"
        wrapper-class="inline"
        :attributes="{class:'check-calculate'}"
    />
    <FormCheckbox label="Важные" wrapper-class="inline" :attributes="{class:'check-important'}" />
</div>
