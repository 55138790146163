<teleport to="body">
    <Modal
        @on-ok="updateDisplayCompareProductsPopup(false)"
        @on-close="updateDisplayCompareProductsPopup(false)"
        :buttons="[]"
    >
        <template v-slot:header>
            <h2 class="d-inline-block mr-1">Сравнение товаров</h2>
            <slot name="header" />
        </template>
        <template v-slot:body>
            <div class="compare-products-container">
                <table>
                    <tr v-for="line in feed">
                        <template v-for="column in line">
                            <td :colspan="column.options?.colspan ? column.options?.colspan : 1">
                                <component
                                    v-if="column.component"
                                    :is="column.component"
                                    :column="column"
                                />
                                <column-default v-else
                                    :column="column"
                                />
                            </td>
                        </template>
                    </tr>
                </table>
            </div>
        </template>
    </Modal>
</teleport>

