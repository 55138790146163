<template src="./selection-stars.html" />
<script>

import './selection-stars.scss';

export default {
    name: 'SelectionStars',
    props: {
        percent: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {
    }
};

</script>
