<script>
export default {
    name: 'PartPrice',
    props: {
        part: {
            type: Object,
            required: true,
            default: () => ({})
        },
        filialId: {
            type: Number,
            required: true,
            default: 1
        },
    },
    computed: {
        price() {
            return this.part?.prices?.[this.filialId];
        }
    }
};
</script>

<template>
    <div>
        {{ price }}
    </div>
</template>
