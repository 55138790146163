export default class Price {

	priceType = {
		PREPAY: 'price',
		RAL: 'rosagroleasing',
		PROGRAM_1432: 'prog1432',
		STOCK: 'stock',

		LEASING: 'leasing',
		SBER: 'sber',
		CREDIT: 'credit',
        INSTALLMENT: 'installment',

		COMMISSIONING: 'commissioning',
	}

	priceTypeDetails = {
		[this.priceType.PREPAY]: {
			title: '100%',
			display: true,
			priceClass: [],
			markClass: ['red'],
		},
		[this.priceType.COMMISSIONING]: {
			title: 'Ввод в эксплуатацию',
			display: false,
			priceClass: [],
			markClass: ['red'],
		},
		[this.priceType.RAL]: {
			title: 'РАЛ',
			display: true,
			priceClass: [],
			markClass: ['gray'],
		},
		[this.priceType.PROGRAM_1432]: {
			title: '1432',
			display: true,
			priceClass: [],
			markClass: ['gray'],
		},
		[this.priceType.STOCK]: {
			title: 'Акция',
			display: true,
			priceClass: ['blue'],
			markClass: ['red'],
		},
		[this.priceType.CREDIT]: {
			title: 'Кредит',
			display: true,
			priceClass: [],
			markClass: ['red'],
		},
		[this.priceType.LEASING]: {
			title: 'Лизинг',
			display: true,
			priceClass: [],
			markClass: ['red'],
		},
        [this.priceType.SBER]: {
            title: 'Сберлизинг',
            display: true,
            priceClass: [],
            markClass: ['red'],
        },
        [this.priceType.INSTALLMENT]: {
            title: 'Рассрочка',
            display: true,
            priceClass: [],
            markClass: ['red'],
        },
	}

	similarPriceTypes = [[this.priceType.STOCK, this.priceType.PREPAY]];
};
