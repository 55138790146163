<template src="./templates/board-mini.html"></template>

<script>
	export default {
		name: 'BoardMini',
		props: {
			title: {
				type: String,
				default() {
					return null;
				}
			},
			subtitle: {
				type: String,
				default() {
					return null;
				}
			}
		},
        methods: {
            toggleOpenRightMenu() {
                //TODO Избавиться от DOM
                const status = document.getElementById('input--layout--sidebar-right').checked;
                if (status === true) {
                    document.getElementById('layout').classList.add('open-menu-right');
                } else {
                    document.getElementById('layout').classList.remove('open-menu-right');
                }
            }
        }
	};
</script>

