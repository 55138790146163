<template src="./templates/assignment.html"></template>
<script>

import FormTextarea from '@f/Textarea';
import FormButton from '@f/Button';
import FormRadio from '@f/Radio';
import Multiselect from '@f/Multiselect';
import FormSelect from '@f/Select';
import DatePicker from '@c/DatePicker';
import eventBus from '@/entities/eventBus';

import {
    getActions,
    saveAssignment,
    getAssignment
} from '@api/assignment';

export default {
    name: 'Assignment',
    components: {
        FormTextarea,
        FormButton,
        FormRadio,
        DatePicker,
        Multiselect,
        FormSelect,
    },
    props: {
        source: {
            type: String,
            default() {
                return null;
            }
        },
        sourceId: {
            type: [String, null],
            default() {
                return null;
            }
        },
        additionalData: {
            type: Object,
            default() {
                return {};
            }
        },
        saveEventName: {
            type: String,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            assignments: null,
            actions: null,
        };
    },
    computed: {
        actionOptions() {
            const options = [];
            
            if (this.actions) {
                this.actions.forEach(v => {
                    options.push({
                        title: v.name,
                        value: v.id,
                    });
                });
            }
            
            return options;
        }
    },
    mounted() {
        getActions().then(data => {
            this.actions = data;
        });
        
        this.initAssignments();
        
        eventBus.$on(this.saveEventName, () => {
            const tt = setInterval(
                () => {
                    if (this.sourceId) {
                        clearInterval(tt);
                        this.saveAssignments();
                    }
                },
                200
            );
        });
    },
    beforeUnmount() {
        eventBus.$off(this.saveEventName);
    },
    methods: {
        initAssignments() {
            getAssignment(
                this.source,
                this.sourceId
            ).then(r => {
                if (r) {
                   this.assignments = r;
                } else {
                    this.assignments = [
                        {
                            code_1c: null,
                            type_id: 1,
                            action_id: 1,
                            date_of_completion: null,
                            text: ''
                        }
                    ];
                }
            });
        },
        saveAssignments() {
            
            saveAssignment(
                this.source,
                this.sourceId,
                this.assignments,
                this.additionalData
            )
            .then(r => {
                if (r) {
                   this.assignments = r;
                } else {
                    this.assignments = [
                        {
                            code_1c: null,
                            type_id: 1,
                            action_id: 1,
                            date_of_completion: null,
                            text: ''
                        }
                    ];
                }
            })
            .catch(r => console.log(r));
        }
    }
};
</script>
