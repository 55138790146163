<div class="questionnaire-content">
    <div>
        <PageTitle
            v-if="questionnaire"
            :sticky="true"
            :title="questionnaire.name"
            :params="{titleTag:'h4',/*date:`Создано:\t${createDate}  ${(editDate)? '/  Изменено: ' + editDate : ''}`*/}"
        />

        <OrderInfo v-model:order-info="selectionValues"/>

        <News :questionnaire-id="questionnaireId"/>

        <KpLinks :result-code="resultCode"/>

        <div class="row c mt-4 flex-wrap" style="flex-wrap: wrap">
            <SelectionResult
                :mode="action"
                :order-info="selectionValues"
                :questions="questionsForBackend"
                :questionsSource="questions"
                :questionnaire-id="questionnaireId"
                :questionnaire="questionnaire"
                :scenario-ratings="scenarioRatings"
                :calculate-titles="calculateTitles"
                :details-calculation="detailsCalculation"
                :add-selected-product="addSelectedProduct"
                :remove-selected-product="removeSelectedProduct"
                v-model:recalculate="recalculate"
                v-model:selected-products="selectedProducts"
                v-model:calculate-result="calculateResult"
                v-model:is-product-prices-displayed="isProductPricesDisplayed"
            />

            <Questions
                v-if="questions.length"
                v-model:questions="questions"
            />

            <Loader v-if="!questions.length" />
        </div>
    </div>

    <SideBar
        v-if="questionnaire"
        :questionnaire-result-id="resultCode"
        :short-list="shortList"
        v-model:questionnaire="questionnaire"
        :questionnaire-progress="questionnaireProgress"
        :model-lines="modelLines"
        :questionnaire-saved-event-name="questionnaireSavedEventName"
        :time-formatted="timeFormatted"
        :order-info="selectionValues"
    />

    <OfferCartForm
        v-if="fromOffer && offerData.products"
        :offer="offerData"
        :selected-products="selectedProductsForCart"
        :save-questionnaire-result-handler="saveQuestionnaireResult"
        :save-order-handler="save1cOrderFromOffer"
        :create-offer-handler="createOfferHandler"
        :result-code="resultCode"
    />
    <CartForm
        v-if="!fromOffer"
        :selected-products="selectedProductsForCart"
        :questionnaire="questionnaire"
        v-model:order-info="selectionValues"
        :add-to-order-handler="addToOrderHandler"
        :save-order-handler="saveOrderHandler"
        :remove-product-handler="removeFromOrderHandler"
        :send-kp-handler="createOfferHandler"
        :leave-page-handler="leavePageHandler"
        :leave-page-resolver="leavePageResolver"
        :page-leaving="pageLeaving"
        :expanded="cartFormExpanded"
        v-model:order-changed="orderDataChanged"
        v-model:calculate-result="calculateResult"
        @open-partner-modal="openPartnerModal"
    />
    <teleport to="body">
        <modal
            v-if="showPartnerModal"
            class="select-partner"
            :close-button="false"
            :buttons="[]"
            @on-close="closePartnerModal"
        >
            <template #body>
                <partner-list
                    :action="'cart'"
                    @on-select="closePartnerModal"
                />
            </template>
        </modal>
    </teleport>
</div>
