<div class="mb-2" v-if="assignments">
    
    <div v-for="(assignment, k) in assignments">
        <FormTextarea
            v-model="assignment.text"
            :attributes="{placeholder:'комментарий к подбору', class:'questionnaire-comment'}"
        />
        
        <FormRadio label="Контакт" v-model="assignment.type_id" :value="1"
            :name="'assigment-type-' + k" :disabled="assignment.code_1c !== null" />
    
        <FormSelect
            :key="'assignments-t-' + k"
            placeholder="Выбрать тип Контакта"
            :options="actionOptions"
            v-model="assignments[k].action_id"
        />
        
        <FormRadio label="Поручение" v-model="assignment.type_id" :value="2" wrapper-class="mw-1"
             :name="'assigment-type-' + k" :disabled="assignment.code_1c !== null" />
        
        <DatePicker v-if="assignment.type_id === 2" v-model="assignment.date_of_completion" />
    
    </div>
</div>
