<template src="./column-delimiter.html"></template>
<script>

export default {
    name: 'ColumnDelimiter',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        title() {
            return this.column.name;
        },
        options() {
            return this.column.options;
        },
    }
};

</script>
