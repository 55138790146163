<template src="../templates/scenario-assessment.html"></template>
<script>

export default {
    name: 'ScenarioAssessment',
    props: {
        scenarioRatings: {
            type: Object,
            default() {
                return null;
            }
        },
    },
    methods: {
        getColorScenarioRate(percent) {
            switch (true) {
                case percent > 75:
                    return 'green';
                case percent >= 50 && percent <= 75:
                    return 'orange';
                default:
                    return 'red';
            }
        },
    }
};
</script>
<style>
    .scenario-rate {
        display: inline-block;
        .percent {
            font-size: 18px;
        }
    }
</style>
