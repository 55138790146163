<div v-if="kpData && kpData.length > 0" class="row c h5 font-weight-normal mb-4 flex-wrap">
    <h5 class="pr-2 w100 pb-1">КП: </h5>
    <template v-for="item in kpData">
        <a :href="item.href" class="mr-4 w100 link" target="_blank">
            <span v-if="item.test" class="badge badge-pill badge-warning mr-1">TEST</span>
            <span v-else-if="!item.offer_id && !item.test" class="badge badge-pill badge-info mr-1">Не отправлено</span>
            {{ item.name }}
        </a>
    </template>
</div>
