<template src="./column-points.html" />
<script>

export default {
    name: 'ColumnPoints',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        item() {
            return this.column.data;
        }
    },
    methods: {
        formatPoints(points) {
            return points.toFixed(2);
        }
    },
};

</script>
