<template src="./templates/index.html"></template>

<script>
export default {
    name: 'Counter',
    props: {
        value: {
            type: Number,
            default: 1,
        }
    },
    emits: ['on-change-value'],
    data() {
        return {
            mutableValue: null,
        };
    },
    mounted() {
        this.mutableValue = this.value;
    },
    methods: {
        increase() {
            this.mutableValue++;
            this.$emit('on-change-value', this.mutableValue);
        },
        decrease() {
            if (this.mutableValue > 0) {
                this.mutableValue--;
                this.$emit('on-change-value', this.mutableValue);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.counter {
    display: inline;
    margin-left: auto;
}

.counter {
    &-btn, &-val {
        display: inline-block;
        width: 20px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
    }

    &-btn {
        &-decrease {
            &:hover {
                color: #d70000;
                font-weight: 800;
                background-color: rgba(135, 23, 23, 0.5);
            }
        }

        &-increase {
            &:hover {
                color: #1c783a;
                font-weight: 800;
                background-color: rgba(23, 73, 7, 0.5);
            }
        }
    }

    &-val {
        width: 20px;
        cursor: default;
        border: .5px solid lightgray;
    }

}
</style>
